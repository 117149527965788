import {createSlice} from '@reduxjs/toolkit';

export interface IAvailableLimitData {
  topUpLimit: boolean;
  limitAmount: number;
  parentLoanId: string;
  loanType: string;
  status: string;
}

export interface ILimits {
  limitAmount: number;
  limitType: string;
  loanType: string;
  id: string | number;
  parentLoanId: string | number;
  status: string;
  isActive: boolean;
}

export interface ILimit {
  limitAmount: number;
  parentLoanId: number | string;
}

interface ITopupLoanApplicationInitial {
  availableLimit: IAvailableLimitData[] | null;
  totalLimit: number;
  topupLoanApplicationId: string | number;
  isTopup: boolean;
  mainLimit: number;
  limit1: ILimit;
  limit2: ILimit;
  limits: ILimits[];
  isTopupStarted: boolean;
  isTopupCompleted: boolean;
}

const initialState: ITopupLoanApplicationInitial = {
  availableLimit: [
    {
      topUpLimit: false,
      limitAmount: 0,
      parentLoanId: '',
      loanType: '',
      status: '',
    },
  ],
  totalLimit: 0,
  topupLoanApplicationId: '',
  isTopup: false,
  mainLimit: 0,
  limit1: {
    limitAmount: 0,
    parentLoanId: '',
  },
  limit2: {
    limitAmount: 0,
    parentLoanId: '',
  },
  limits: [
    {
      limitAmount: 0,
      limitType: '',
      loanType: '',
      id: '',
      parentLoanId: '',
      status: '',
      isActive: false,
    },
  ],
  isTopupStarted: false,
  isTopupCompleted: false,
};

const topup = createSlice({
  name: 'topupLoanApplication',
  initialState,
  reducers: {
    setLoanAvailableLimit(state, actions) {
      state.availableLimit = actions.payload;
    },
    setLoanTotalLimit(state, actions) {
      state.totalLimit = actions.payload;
    },
    setTopupLoanApplicationId(state, actions) {
      state.topupLoanApplicationId = actions.payload;
    },
    setIsTopup(state, actions) {
      state.isTopup = actions.payload;
    },
    setMainLimit(state, actions) {
      state.mainLimit = actions.payload;
    },
    setLimit1(state, actions) {
      state.limit1 = {
        ...state.limit1,
        ...actions.payload,
      };
    },
    setLimit2(state, actions) {
      state.limit2 = {
        ...state.limit2,
        ...actions.payload,
      };
    },
    setLimits(state, actions) {
      state.limits = actions.payload;
    },
    setIsTopupStarted(state, actions) {
      state.isTopupStarted = actions.payload;
    },
    setIsTopupCompleted(state, actions) {
      state.isTopupCompleted = actions.payload;
    },
    resetState() {
      return initialState;
    },
  },
});

const topupLoanApplicationReducer = topup.reducer;

export const topupLoanApplicationActions = topup.actions;

export default topupLoanApplicationReducer;
