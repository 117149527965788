import seedFlexLogo from '@assets/icons/seedflexLogo.svg';
import seedflexTabLogo from '@assets/icons/seedflexTabLogo.svg';
import {
  LANGUAGES,
  PATH,
  WHATS_APP_NUMBER,
  WHATS_APP_NUMBER_ID,
} from '@common/constants';
import {Avatar, useMediaQuery} from '@mui/material';
import HomeIcon from '@shared/icons/HomeIcon';
import ProfileIcon from '@shared/icons/Profile';
import QuestionIcon from '@shared/icons/QuestionIcon';
import {useEffect, useState} from 'react';
import {useLocation} from 'react-router';
import {useTranslation} from 'react-i18next';
import Dropdown from '@shared/components/Dropdown';
import {ILanguage} from '@common/interface';
import PrimaryLogout from '@assets/icons/PrimaryLogout.svg';

import {RootState, dispatch} from '@store';
import {
  loginActions,
  loginReducerLogout,
} from '@pages/Login/auth/store/loginReducer';
import {logoutApi} from '@pages/Login/api';
import LogoutModal from '@pages/Login/component/LogoutModal';
import {topupLoanApplicationActions} from '@pages/LoanApplication/store/topupLoanApplicationReducer';
import {loanApplicationActions} from '@pages/LoanApplication/store/loanApplicationReducer';
import {useSelector} from 'react-redux';
import {
  AvatarWrapper,
  GridItem,
  IconWrapper,
  ListItemTextContent,
  Logo,
  LogoutButton,
  SidebarDropdown,
  SidebarItem,
  SidebarItems,
  SidebarWrapper,
} from './Sidebar.style';
import Stepper from '../Stepper';

interface ISidebar {
  icon: React.ReactNode;
  link: string;
  text: string;
  id: string;
}

export default function Sidebar() {
  const {stepToRedirect, step, countryCode} = useSelector(
    (state: RootState) => state.login
  );
  const location = useLocation();
  const isTabScreen = useMediaQuery('(max-width:1120px)');
  const isMobScreen = useMediaQuery('(max-width:480px)');

  const [activeItem, setActiveItem] = useState<string>('Home');
  const [open, setOpen] = useState<boolean>(false);
  const {t, i18n} = useTranslation();
  const [selectedLang, setSelectedLang] = useState<ILanguage>();

  const items: ISidebar[] = [
    {
      icon: (
        <HomeIcon
          color={activeItem === 'Home' ? '#000000' : '#757575'}
          width='24px'
          height='24px'
        />
      ),
      link: PATH.DASHBOARD,
      text: t('sidebar.MP_SIDEBAR_HOME'),
      id: 'Home',
    },
    {
      icon: (
        <ProfileIcon
          color={activeItem === 'My Profile' ? '#121212' : '#757575'}
          width='24px'
          height='24px'
        />
      ),
      text: t('sidebar.MP_SIDEBAR_PROFILE'),
      link: PATH.PROFILE,
      id: 'My Profile',
    },
    {
      icon: (
        <QuestionIcon
          color={activeItem === 'Contact Us' ? '#000000' : '#757575'}
          width='24px'
          height='24px'
        />
      ),
      text: t('sidebar.MP_SIDEBAR_CONTACT'),
      link: `https://wa.me/${
        countryCode === 'ID' ? WHATS_APP_NUMBER_ID : WHATS_APP_NUMBER
      }`,
      id: 'Contact Us',
    },
  ];
  const handleItemClick = (item: string) => {
    setActiveItem(item);
  };

  const LogoutResponse = async () => {
    const res = await logoutApi();
    if (res?.data?.message === 'Log out successfully') {
      // dispatch(loginActions.logout());
      dispatch(loginReducerLogout());
      dispatch(loginActions.setIsShowOtp(false));
      dispatch(loginActions.setIsSelectBRN(false));
      dispatch(loginActions.setIsLogout(true));
      dispatch(loginActions.setCountryCode(''));
      dispatch(topupLoanApplicationActions.resetState());
      dispatch(loanApplicationActions.resetState());
    }
  };
  const handleLogout = () => {
    LogoutResponse();
  };
  const handleClickLogoutIcon = () => {
    setOpen(true);
  };
  const handleCloseModal = () => {
    setOpen(false);
  };

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  };

  useEffect(() => {
    const langFromStorage = localStorage.getItem('i18nextLng');

    if (langFromStorage) {
      const foundLang = LANGUAGES.find(
        (lang) => lang.value === langFromStorage
      );

      if (foundLang) {
        setSelectedLang(foundLang);
      }
    }
  }, []);

  return (
    <GridItem item xs={9.65} sm={4} md={2.45} lg={2.45} xl={1.8}>
      {location.pathname !== '/' && isMobScreen ? (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <></>
      ) : (
        <SidebarWrapper isMobScreen={isMobScreen}>
          <SidebarItems isMobScreen={isMobScreen}>
            {!isTabScreen && !isMobScreen && (
              <Logo src={seedFlexLogo} alt='Logo' />
            )}
            {isTabScreen && !isMobScreen && (
              <Logo src={seedflexTabLogo} alt='TabLogo' />
            )}
            {stepToRedirect ? (
              <div className='stepper-section'>
                <Stepper currentStep={step} orientation='vertical' />
              </div>
            ) : (
              items.map((item) => (
                <SidebarItem
                  isMobScreen={isMobScreen}
                  key={item.id}
                  to={item.link}
                  target={item.id.includes('Contact') ? '_blank' : '_self'}
                  onClick={() => handleItemClick(item.id)}
                  isActive={activeItem === item.id}>
                  <IconWrapper
                    isMobScreen={isMobScreen}
                    isActive={activeItem === item.id}>
                    {item.icon}
                  </IconWrapper>
                  <ListItemTextContent
                    isMobScreen={isMobScreen}
                    isActive={activeItem === item.id}
                    primary={item.text}
                  />
                </SidebarItem>
              ))
            )}
          </SidebarItems>
          {countryCode === 'ID' ? (
            <SidebarDropdown>
              <Dropdown
                value={selectedLang?.value || 'en'}
                manuList={LANGUAGES.map((lang) => ({
                  value: lang.value,
                  text: lang.text,
                }))}
                handleChange={(val) => {
                  const selected = LANGUAGES.find((lang) => lang.value === val);
                  setSelectedLang(selected);
                  changeLanguage(val);
                }}
                placeholderText=''
              />
            </SidebarDropdown>
          ) : (
            ''
          )}
          <LogoutButton onClick={handleClickLogoutIcon}>
            <AvatarWrapper>
              <Avatar alt='Logout' src={PrimaryLogout} />
            </AvatarWrapper>
            <div className='logout-text'>{t('sidebar.MP_SIDEBAR_LOGOUT')}</div>
          </LogoutButton>

          <LogoutModal
            isOpen={open}
            handleLogout={handleLogout}
            handleCancle={handleCloseModal}
          />
        </SidebarWrapper>
      )}
    </GridItem>
  );
}
